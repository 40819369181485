import { effect, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  currentTheme = signal<string | null>(null);

  /**  Set theme dynamically */
  setTheme(themeName: string | null): void {
    if (themeName && themeName !== this.currentTheme()) {
      this.currentTheme.set(themeName);
      this.applyTheme(themeName);
    }
  }

  /**  Apply the selected theme */
  private applyTheme(themeName: string): void {
    const themeId = 'theme-style';
    const themePath = `${themeName}-theme.css`; // Ensure styles exist

    let existingLink = document.getElementById(themeId) as HTMLLinkElement;

    //  Remove old styles if they exist
    if (existingLink) {
      existingLink.parentNode?.removeChild(existingLink);
    }

    //  Add the new theme
    const link = document.createElement('link');
    link.id = themeId;
    link.rel = 'stylesheet';
    link.href = themePath;
    link.onload = () => console.log(` Theme applied: ${themeName}`);
    link.onerror = () => console.error(`Theme load failed: ${themePath}`);

    document.head.appendChild(link);
  }
}