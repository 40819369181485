import { computed, inject, Pipe, PipeTransform, Signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ThemeService } from '../../services/theme.service';

@Pipe({
  name: 'themeSource',
  standalone: false,
  pure: false
})
export class ThemeSourcePipe implements PipeTransform {

  private themeService = inject(ThemeService);
  
  transform(imgName: string): string {
    const currentTheme = this.themeService.currentTheme();
    if (!currentTheme || !imgName) return ''
    return `${environment.urls.assets}/${currentTheme}/${imgName}`;
  }
}
