import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Location } from '@angular/common';
import { MenuService } from '../../menu/menu.service';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
    standalone: false
})
export class TopBarComponent {

  @Input() title: string;
  @Input() enableClose: boolean;
  @Input() isDialog = false;

  @Output() closed = new EventEmitter<boolean>();

  constructor(
    private location: Location,
    private menuService: MenuService
  ) {}

  public goBack(): void {
    if (this.location.path() === '/order') {
      this.menuService.setCurrentSectionByName();
    } else {
      this.location.back();
    }
  }

  public closeDialog(): void {
    this.closed.emit(true);
  }

}
